import React, { useState, createContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import Client from 'shopify-buy';
import window from '../utils/window';

const client = Client.buildClient(
    {
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    },
    fetch
);

const defaultValues = {
    cart: [],
    isOpen: false,
    loading: false,
    onOpen: () => {},
    onClose: () => {},
    addVariantToCart: () => {},
    removeLineItem: () => {},
    updateLineItem: () => {},
    client,
    checkout: {
        lineItems: [],
    },
    error: null,
};

export const StoreContext = createContext(defaultValues);

const { localStorage } = window;

const localStorageKey = 'sitteroo_checkout_id';

export const StoreProvider = ({ children }) => {
    const [checkout, setCheckout] = useState(defaultValues.checkout);
    const [loading, setLoading] = useState(false);
    const [didJustAddToCart, setDidJustAddToCart] = useState(false);
    const [error, setError] = useState(null);

    const setCheckoutItem = (_checkout) => {
        localStorage.setItem(localStorageKey, _checkout.id);
        setCheckout(_checkout);
    };

    useEffect(() => {
        const initializeCheckout = async () => {
            const existingCheckoutID =
                localStorage.getItem(localStorageKey) || null;

            if (existingCheckoutID && existingCheckoutID !== 'null') {
                try {
                    const existingCheckout = await client.checkout.fetch(
                        existingCheckoutID
                    );
                    if (!existingCheckout.completedAt) {
                        setCheckoutItem(existingCheckout);
                        return;
                    }
                } catch (e) {
                    localStorage.setItem(localStorageKey, null);
                }
            }

            const newCheckout = await client.checkout.create();
            setCheckoutItem(newCheckout);
        };

        initializeCheckout();
    }, []);

    const addVariantToCart = (variantId, quantity, customAttr) => {
        setLoading(true);

        const checkoutID = checkout.id;

        const lineItemsToUpdate = [
            {
                variantId,
                quantity: parseInt(quantity, 10),
                customAttributes: [...customAttr],
            },
        ];

        return client.checkout
            .addLineItems(checkoutID, lineItemsToUpdate)
            .then((res) => {
                setCheckout(res);
                setLoading(false);
                setDidJustAddToCart(true);
                setTimeout(() => setDidJustAddToCart(false), 3000);
            })
            .then(navigate('/shop/cart'))
            .catch((e) => {
                setError(e.message);
            });
    };

    const removeLineItem = (checkoutID, lineItemID) => {
        setLoading(true);

        return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then((res) => {
                setCheckout(res);
                setLoading(false);
            })
            .catch((e) => {
                setError(e.message);
            });
    };

    const updateLineItem = (checkoutID, lineItemID, quantity) => {
        setLoading(true);

        const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
        ];

        return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then((res) => {
                setCheckout(res);
                setLoading(false);
            })
            .catch((e) => {
                setError(e.message);
            });
    };

    return (
        <StoreContext.Provider
            value={{
                ...defaultValues,
                addVariantToCart,
                removeLineItem,
                updateLineItem,
                checkout,
                loading,
                didJustAddToCart,
                error,
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};
