exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-subscribe-js": () => import("./../../../src/pages/contact/subscribe.js" /* webpackChunkName: "component---src-pages-contact-subscribe-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-shop-cart-js": () => import("./../../../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-products-js": () => import("./../../../src/pages/shop/products.js" /* webpackChunkName: "component---src-pages-shop-products-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-star-map-page-js": () => import("./../../../src/templates/star-map-page.js" /* webpackChunkName: "component---src-templates-star-map-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

