/**
 * Are we in the browser?
 * @return {boolean} True if the window object is present
 */

function inBrowser() {
    return Boolean(typeof window === 'object');
}

/**
 * Mimick window local storage
 * @returns {{setItem: setItem, getItem: getItem, removeItem: removeItem}}
 */
function fakeStorage() {
    const storage = {};

    function setItem(key, value) {
        storage[key] = value || '';
    }

    function getItem(key) {
        return key in storage ? storage[key] : null;
    }

    function removeItem(key) {
        delete storage[key];
    }

    return {
        setItem,
        getItem,
        removeItem,
    };
}

const windowMock = {
    document: {
        cookie: '',
        querySelector: () => {},
        querySelectorAll: () => {},
        body: {
            classList: {
                add: () => {},
                remove: () => {},
            },
            style: {
                overflow: '',
            },
        },
    },
    location: { hash: '', location: { hostname: '' }, href: '', search: '' },
    matchMedia: () => ({ matches: false }),
    localStorage: fakeStorage(),
    sessionStorage: fakeStorage(),
    console: { warn: () => {} },
    addEvenListener: () => {},
    removeEventListener: () => {},
    MutationObserver: (fn) => (mutations) => fn(mutations),
    history: { pushState: () => {} },
    fbq: null,
};

export function ga4() {
    if (typeof gtag !== 'undefined') {
        // eslint-disable-next-line no-undef
        return gtag;
    }
    return null;
}
// Window helper
// eslint-disable-next-line no-undef
export default inBrowser() ? window : windowMock;
